@import "../master";

body {

    overflow: hidden;
    overscroll-behavior: none;
    // overflow-y: hidden
}
a:active,
a:hover,
a:visited {
    color: inherit;
}
html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
}

#wrapper {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
}

.date-filter-parent {
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: 1fr 1fr;
}

.e-paper-parent {
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: -webkit-fill-available;
    background-color: black;
    position: relative;
    overflow: hidden;
}

.e-paper-top-bar {
    z-index: 100;
    padding: 0.5em;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    display: flex;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
}

.e-paper-date {
    font-family: $common-font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
}

.icon-button {
    width: 2.5em;
    height: 2.5em;
}

.e-paper-page {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
}

.e-paper-bottom-navigation-parent {
    overscroll-behavior-y: contain;
    z-index: 50;
    position: absolute;
    bottom: 0;
    margin-bottom: 0.5em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none; 
}

.epaper_image {
    box-sizing: border-box;
    border-radius: 8px;
    height: 120px;
    display: inline;
    float: none;
    cursor: pointer;
    border: 2px solid #FFFFFF00;
}
.epaper_image:hover {
    border: 2px solid #FF4349;
}
.e-paper-bottom-navigation {
    background-color: white;
    height: 2.8em;
    object-fit: contain;
    border-radius: 0.75em;
}

.e-paper-bottom-navigation-button {
    height: 80%;
    object-fit: contain;
    box-sizing: border-box;
    pointer-events: visible;
    margin-top: 0.25em;
}

.zoom {
    zoom: 1;
}

.magazine {
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.magazine .page {
    height: 100%;
}

.magazine .page img {
    max-width: 100%;
    height: 100%;
}

.relative {
    position: relative;
}

.max-size {
    width: 100%;
    height: 100%;
}

.absolute {
    position: absolute;
    top: 0;
    left: 0;
}
.bracket:hover {
    border-radius: 0.25em;
    background-color: white;
    opacity: 0.20;
    cursor: pointer;
    filter: blur(1px);
}

.title {
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 24px * $multiplier;
    color: $primary-color;
}

.sub-title {
    width: 100%;
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: $primary-color;
    padding-top: 1em;
}

.home-parent {
    // height: 100vh;
    max-width: 1400px;
    padding: 1em;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 228px);
}

.home-split {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 0.5em;

    .latest-news {
        // width: 48%;
        margin-right: 1rem;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .more-news {
        // flex-grow: 1;
        width: 50%;
    }

}
@media screen and (max-width: $medium-width) {}

.loader-container {
    padding: 2em;
}

.text-info {
    font-family: $common-font;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    opacity: 0.75;
    margin-top: 1rem;
}

.w-100 {
    width: 100% !important;
}

.logo {
    height: 96px;
    max-width: min(50%, 250px);
    object-fit: contain;
    // margin-bottom: 1em;
}

.space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.search-icon {
    height: 2.0em;
    cursor: pointer;
}

.title-input {
    font-family: $common-font;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: $primary-color;
    margin-bottom: 0.5em;
}

.filter-parent {
    border: 2px solid $primary-color-light;
    border-radius: 0.5em;
    padding: 0.5em 1em;
    display: flex;
}

.filter-input {
    border: 0;
    box-sizing: border-box;
    font-size: large;
    font-weight: 300;
    width: 100%;
    font-family: $common-font;
}
.filter-input:focus {
    border: 0;
    outline: none;
}

.button-icon {
    cursor: pointer;
    font-family: $common-font;
    box-sizing: border-box;
    height: 1em;
}

.ePaperPage-filter {
    box-sizing: border-box;
    width: 100%;
    margin: 1em 0;
}

.latest-news-parent {
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 1em;
    aspect-ratio: 2/3;
    position: relative;
    box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.125);
    max-width: 100%;
}

.latest-news-image {
    border-radius: 1em;
    height: 100%;
    max-height: 80vh;
    // height: min(750px, 75vh);
    // width: calc(min(750px, 75vh) / 4677 * 3307);
    object-fit: cover;
    object-position: top;
}

.blur-off {
    filter: blur(5px) brightness(0.5);
    opacity: 0.5;
}

.black-fade-bottom {
    border-radius: 1em;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
}

.latest-news-info-parent {
    pointer-events: none;
    border-radius: 1em;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 2em;
    box-sizing: border-box;
}

.latest-news-info-date {
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 12px * $multiplier;
    line-height: 16px * $multiplier;
    display: flex;
    align-items: center;
    opacity: 0.75;
    color: white;
    margin-bottom: 1em;
}

.latest-news-info-title {
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 20px * $multiplier;
    line-height: 27px * $multiplier;
    display: flex;
    align-items: center;
    color: white;
    margin-bottom: 1.5em;
}

.latest-news-info-hashtag {
    font-family: $common-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px * $multiplier;
    line-height: 16px * $multiplier;
    display: flex;
    align-items: center;
    color: white;
    margin-bottom: 1em;
}

.latest-news-info-readtime {
    font-family: $common-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px * $multiplier;
    line-height: 16px * $multiplier;
    display: flex;
    align-items: center;
    opacity: 0.75;
    color: white;
}

.more-icon-button {
    pointer-events: all;
    cursor: pointer;
    width: 2em;
    height: 2em;
    border-radius: 2em;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1em;
    margin: 1em;
    background-color: #FFFFFF00;
}
.more-icon-button:hover {
    background-color: #FFFFFF40;
}

.red-button {
    pointer-events: all;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    background: $primary-color;
    box-shadow: 0 4px 4px rgba(255, 67, 73, 0.5);
    border-radius: 4px * $multiplier;
    margin: 1em;
    color: white;
    padding: 1em 2em;
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 12px * $multiplier;
    line-height: 16px * $multiplier;
    text-align: center;
    &:hover {
        background: $primary-color-darker;
    }
}

.more-parent {
    background-color: #00000080;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 0.5em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    backdrop-filter: blur(2px);
}

.more-items-parent {
    cursor: pointer;
    padding: 2em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #00000000;
    border-radius: 2em;
}
.more-items-parent:hover {
    background-color: #FFFFFF20;
}

.more-items-icon {
    width: 2.5em;
    height: 2.5em;
}

.more-items-text {
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 12px * $multiplier;
    line-height: 16px * $multiplier;
    display: flex;
    align-items: center;
    color: white;
    text-align: center;
}

.e-paper-card-parent {
    width: 100%;
    box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.125);
    border-radius: 0.5em;
    margin-bottom: 1.5em;
    box-sizing: border-box;
    height: 256px;
    display: flex;
    position: relative;
    @media screen and (max-width: $max-width) {
        .wrapper {
            width: 100%;
        }
    }
    @media screen and (max-width: 600px) {
        height: 512px;
        flex-direction: column;
    }
}

#discover {
    .favourites-result,
    .search-result {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .favourites-result {
        margin-top: 15px;
    }
    @media screen and (min-width: $max-width) {
        .e-paper-card-parent {
            display: inline-flex;
            width: 49%;
        }
    }
}

.e-paper-card-thumbnail {
    border-radius: 0.5em 0 0 0.5em;
    height: 256px;
    object-fit: cover;
    @media screen and (max-width: $max-width) {
        height: 100%;
    }
    @media screen and (max-width: 600px) {
        height: 50%;
        border-radius: 0.5em 0.5em 0 0;
        object-fit: cover;
        object-position: top;
    }
}

.e-paper-card-title {
    font-family: "Noto Sans SC";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    justify-content: space-between;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.see-more-icon {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border-radius: 2em;
    padding: 8px;
    &:hover {
        background: #75757540;
    }
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0 4px 4px 0 #00000080;
    border-radius: 8px;
    z-index: 1;
    right: 18px;
}

.dropdown-item {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    color: $primary-color;
    border-radius: 8px;
    padding: 12px 16px;
    &:hover {
        background-color: #e6e6e6;
    }

    .label {
        margin-left: 10px;
    }
}

.e-paper-card-date {
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 12px * $multiplier;
    line-height: 16px * $multiplier;
    opacity: 0.5;
    margin-top: 1em;
}

.e-paper-card-ongoing-page {
    font-family: "Noto Sans SC";
    font-weight: 600;
    font-size: 15px;
    opacity: 0.65;
    margin-bottom: 0.5em;
    text-align: end;
}

.e-paper-card-info-parent {
    padding: 1em;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.e-paper-info-bottom-row {
    display: flex;
    justify-content: space-between;
    // position: absolute;
    bottom: 0;
    left: 0;
    margin-top: 1em;
    width: 100%;
    box-sizing: border-box;
    align-items: flex-end;
}

.e-paper-info-hashtags {
    display: flex;
    height: min-content;
}

.e-paper-info-hashtag {
    background: #FFFFFF;
    border: 2px solid $primary-color;
    border-radius: 1em;
    color: $primary-color;
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 10px * $multiplier;
    line-height: 14px * $multiplier;
    padding: 0.35em 0.6em;
    margin-right: 0.5em;
    cursor: pointer;
}

.start-reading {
    width: unset;
    height: unset;
    box-sizing: unset;
}
@media screen and (max-width: $max-width) {
    .home-parent {
        max-width: $max-width;
        height: 100%;
    }

    .home-split {
        grid-template-columns: 100%;
    }

    .e-paper-info-bottom-row {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .start-reading {
        width: 100%;
        box-sizing: border-box;
    }

    .latest-news-parent {
        max-height: 60vh;
    }

    .latest-news-image {
        max-height: 60vh;
        height: 60vh;
    }
}

.tab-parent {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.tab-activate {
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 18px * $multiplier;
    line-height: 25px * $multiplier;
    display: flex;
    align-items: center;
    color: $primary-color;
    padding: 0.5em 1em 0.5em 0;
    cursor: pointer;
}

.tab-deactivate {
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 16px * $multiplier;
    line-height: 22px * $multiplier;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.25);
    padding: 0.5em 1em 0.5em 0;
    cursor: pointer;
}

.recommendation-parent {
    display: flex;
    position: relative;
    justify-content: center;
    margin-top: 1em;
}

.recommendation-image {
    position: relative;
    width: 100%;
    border-radius: 1em;
    box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.125);
}

.recommendation-text {
    font-family: $common-font;
    font-style: normal;
    font-weight: 500;
    font-size: 13px * $multiplier;
    line-height: 18px * $multiplier;
    display: flex;
    align-items: center;
    text-align: center;
    color: $primary-color;
}

.full-size {
    width: 100%;
    height: 100%;
}

.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
    box-shadow: unset !important;
}

.top-black-fade {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 10vh;
    z-index: 20;
    pointer-events: none;
    background: linear-gradient(0deg, #00000000, rgba(0, 0, 0, 0.5));
}

.bottom-black-fade {
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 10vh;
    z-index: 20;
    pointer-events: none;
    background: linear-gradient(180deg, #00000000, rgba(0, 0, 0, 0.5));
}

.video-parent {
    background-color: #000000D0;
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    z-index: 500;
}

.video-close-button {
    top: 0;
    right: 0;
    margin: 2em;
    position: absolute;
    cursor: pointer;
}

.search-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 1.5rem;
}

.active-recommendation-card {
    cursor: auto;
}

.inactive-recommendation-card {
    cursor: pointer;
    filter: brightness(40%);
}

.recommendation-info-card {
    margin: 25px 0 50px;
    padding: 15px;
    width: 50%;
    border-radius: 1em;
    box-shadow: 0 0 0 2px $primary-color;
    position: relative;

    .top-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .e-paper-card-date {
        margin-top: 30px;
        margin-bottom: 15px;
    }
    @media screen and (max-width: $max-width) {
        width: 100%;
    }
}

.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.text-center {
    text-align: center;
}
@keyframes scale-1 {
    0% {
        -webkit-transform: scale(1);
    }

    100% {
        -webkit-transform: scale(1.5);
    }
}
@keyframes scale-2 {
    0% {
        -webkit-transform: scale(1.5);
    }

    100% {
        -webkit-transform: scale(2);
    }
}
@keyframes scale-3 {
    0% {
        -webkit-transform: scale(2);
    }

    100% {
        -webkit-transform: scale(2.5);
    }
}
@keyframes descale-3 {
    0% {
        -webkit-transform: scale(2.5);
    }

    100% {
        -webkit-transform: scale(2);
    }
}
@keyframes descale-2 {
    0% {
        -webkit-transform: scale(2);
    }

    100% {
        -webkit-transform: scale(1.5);
    }
}
@keyframes descale-1 {
    0% {
        -webkit-transform: scale(1.5);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

.more-news-scroll {
    max-height: 80vh;
    overflow-y: scroll;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    box-sizing: border-box;
}

.title-height {
    height: 5%;
}

.divider-percent {
    margin-top: 0.5%;
    margin-bottom: 0.5%;
}

.card-wrapper {
    height: 100%;
    width: 100%;
    padding: 2em;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.hashtag-recommendation {
    box-sizing: border-box;
    font-family: $common-font;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;

    padding: 1em;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    color: $primary-color;
}

.white-bg {
    background-color: white;
}

.fill-height {
    max-height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    height: 100vh;
}

.center-h {
    margin-top: 3em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.loading-text {
    font-family: $common-font;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: black;
}

.footer {
    margin-top: 64px;
    background-color: #252525;
    width: 100vw;
    padding: 2em;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    box-sizing: border-box;
    height: 164px;
    // position: fixed;
    // bottom: 0;
}

.footer-icons {
    display: flex;
    gap: 0.5em;
    margin-top: 1em;
}

.footer-icon {
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;
    pointer-events: all;
}
.footer-icon:hover {
    filter: brightness(1.25);
}
@media screen and (max-width: $max-width) {
    .card-wrapper {
        padding: 1em 0;
    }

    .date-filter-parent {
        grid-template-columns: 100%;
    }

    .home-split {
        flex-direction: column;
        align-items: center;

        .latest-news {
            width: 100%;
            margin-right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .content {
            width: calc(0.7 * 60vh);
            margin-right: 0;
        }

        .more-news {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .red-button {
            margin: 1em 0;
        }

        .latest-news-info-date {
            font-size: 14px;
        }

        .latest-news-info-title {
            font-size: 20px;
        }

        .latest-news-info-hashtag {
            font-size: 14px;
            opacity: 0.85;
        }

        .latest-news-info-readtime {
            font-size: 14px;
        }

    }
}

.dark-backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(4px);
}

.page-navigation-popup {
    width: 100vw;
    top: 0;
    display: flex;
    pointer-events: all;
    justify-content: center;
}

.page-navigation-placement {
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    flex-direction: column;
}

.current-epaper {
    border: 3px solid rgb(255, 67, 73);
}

.navigation-page-text {
    color: white;
    text-shadow: 1px 1px 2px black;
    padding: 0.5em;
    opacity: 0.65;
    font-family: $common-font;
    font-weight: 700;
}

.page-navigation-container {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: rgb(0 0 0 / 25%) 0 4px 4px;
    border-radius: 8px;
    max-width: calc(100vw - 4em);
}

.show-out {
    opacity: 0;
    transform: translateY(2em);
    pointer-events: none !important;
    animation: showout 0.25s;
}

.show-in {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all !important;
    animation: showin 0.25s;
}

.fade-out {
    opacity: 0;
    pointer-events: none !important;
    animation: fadeout 0.25s;
}

.fade-in {
    opacity: 1;
    pointer-events: all !important;
    animation: fadein 0.25s;
}
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@keyframes showin {
    from {
        opacity: 0;
        transform: translateY(2em);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes showout {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(1em);
    }
}

.navigation-page-scroller {
    display: flex;
    overflow-x: auto;
    border-radius: 20px;
    gap: 16px;
    padding: 16px;
}

.btm-left {
    position: absolute;
    bottom: 0;
    left: 0;
}

.btm-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.p-a-m {
    padding: 1em;
}

.brighten-on-hover {
    filter: brightness(1);
}
.brighten-on-hover:hover {
    filter: brightness(1.25);
}

.e-paper-card-info-top {
    display: flex;
    justify-content: space-between;
}

.magazine .page {
    background-color: white;
}

.cookies-dialog {
    font-family: $common-font;
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: #f7cf79;
    padding: 1em;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    z-index: 1000;
    justify-content: space-between;
}

.cookies-dialog-link {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.cookies-dialog-button {
    align-self: flex-end;
    background-color: rgba(0, 0, 0, 0.7333333333);
    padding: 0.5em;
    box-sizing: border-box;
    width: fit-content;
    text-align: center;
    min-width: 64px;
    color: white;
    cursor: pointer;
}

.strike {
    text-decoration: line-through;
}

.download-app-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.download-button-text{
    font-weight: 700;
    font-size: 10px;
    color: #FFFFFF;

}

.share-button-page-selection-dropdown{
    position: absolute;
    top:55px;
    right:0;
    z-index:300;
    background: white;
    border-radius: 8px;
    padding:1em;
    margin:1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    
}
.share-button-page-selection-text{
    padding:0.5em;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    color: #FF4349;
    cursor:pointer
}

